<template>
  <spin-container>
    <side-panel v-model="startingTab">
      <template #above>
        <SidePanelDetails :padding-vertical="1.22">
          <template #open>
            <strong>Super Reports</strong>
          </template>

          <template #closed>
            <font-awesome-icon icon="file-chart-pie" style="font-size: 1.5em; margin-left: -5px" />
          </template>
        </SidePanelDetails>
      </template>

      <panel title="All Reports" icon="chart-line" class="fixed-full" :container="false">
        <ScrollContainer>
          <Container>
            <CardSection class="mt-2">
              <template #title> Select a report </template>
              <Card
                class="mb-3 button"
                v-for="report in reports"
                :key="`card-${report.reportType}`"
                @click="startingTab = [report.title]"
              >
                <template #icon>
                  <font-awesome-icon icon="table" />
                </template>
                <span class="title">{{ report.title }}</span>
              </Card>
            </CardSection>
          </Container>
        </ScrollContainer>
      </panel>

      <panel
        v-for="report in reports"
        :key="`panel-${report.reportType}`"
        :title="report.title"
        icon="table"
        class="fixed-full"
        :container="false"
      >
        <Report :reportType="report.reportType" :entity="report.entity" :isSuperReport="true" />
      </panel>
    </side-panel>
  </spin-container>
</template>

<script>
import PageMixin from '../mixins/Page'
import Report from '../ui/Report/Report.vue'
import SidePanelDetails from '../ui/SidePanelDetails.vue'

export default {
  components: { SidePanelDetails, Report },
  mixins: [PageMixin],

  data() {
    return {
      reportId: this.$route.params.id,

      reports: [
        {
          title: 'Users',
          reportType: 'super_users',
          entity: 'user'
        },
        {
          title: 'Companies',
          reportType: 'super_companies',
          entity: 'company'
        },
        {
          title: 'Super Items',
          reportType: 'super_items',
          entity: 'cost_type'
        }
      ],
      startingTab:
        this.$route.query && this.$route.query.tab
          ? c.makeArray(this.$route.query.tab)
          : ['All Reports']
    }
  },

  computed: {
    routeReport() {
      return this.$route.params.id
    }
  },

  watch: {
    startingTab(tab) {
      this.$router
        .push({
          query: {
            tab
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
